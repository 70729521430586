<template>
  <div>
    <div>
      <div class="head">
        <div style="display: flex; justify-content: center">
          <div>
            <div class="headBanner">
              <div class="logoImg">
                <img style="width: 100%; height: 100%"
                     :src="this.$store.state.m_user.logo"
                     alt="" />
              </div>

              <div class="searChBox">
                <div style="width: 271px">
                  <el-input class="searCh"
                            placeholder="搜索感兴趣的课程资源"
                            v-model="className"
                            prefix-icon="el-icon-search"
                            ></el-input>
                </div>
                <!-- <input /> -->
                <!-- <img class="searchIcon"
                   src="../../assets/indexImg/search.png"
                   alt=""> -->
                <div class="searBtn"
                     @click="searClass">搜 索</div>
              </div>

              <div class="userxi"
                   v-if="this.$store.state.m_user.realName == '' || this.$store.state.m_user.realName == null">
                <div style="cursor: pointer"
                     class="mlet hov"
                     @click="login">登录</div>
                <div class="mlet">/</div>
                <div style="cursor: pointer"
                     class="mlet hov"
                     @click="regist">注册</div>
              </div>

              <div class="userxi"
                   v-else>
                <div style="cursor: pointer; color: #2878ff"
                     class="mlet hov"
                     @click="goStudy">{{ this.$store.state.m_user.realName }}</div>
                <div class="mlet">|</div>
                <div style="cursor: pointer"
                     class="mlet hov"
                     @click="loginOut">退出</div>
              </div>
            </div>
            <div class="navBox"
                 style="position: relative">
              <div class="tab1">
                <div style="display: flex"
                     @click="changeKe">
                  <img class="lu"
                       src="../../assets/indexImg/lu.png"
                       alt="" />
                  <div class="textLus">课 程 类 目</div>
                  <img v-if="iconSHow"
                       class="topIcon"
                       src="../../assets/indexImg/topIcon.png"
                       alt="" />
                  <img v-if="!iconSHow"
                       class="topIcon"
                       src="../../assets/indexImg/arrow_down.png"
                       alt="" />
                </div>

                <div class="tab1List"
                     v-if="iconSHow"
                     style="">
                  <div style="position: relative; z-index: 10000">

                    <div class="list1"
                         v-for="item in this.$store.state.m_user.list"
                         :key="item.id"
                         style="margin-top: 20px;">
                      {{ item.codeName }}

                      <div class="slist">
                        <div class="slistbox"
                             v-for="msg in item.codeList"
                             :key="msg.id"
                             @click="goList(msg, item)">
                          {{ msg.codeName }}
                        </div>
                        <div class="slistbox"
                             style="color: #1f73bc; text-decoration: underline"
                             @click="moke">更多</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div :class="path == '/' ? 'tab active' : 'tab'"
                   @click="navGet('1')">网站首页</div>
              <div :class="path == '/lsitFs' ? 'tab active' : 'tab'"
                   @click="navGet('2')">报名选课</div>
              <div :class="path == '/car' ? 'tab active' : 'tab'"
                   @click="navGet(3)">购物车</div>
              <div :class="path == '/studyMain'? 'tab active' : 'tab'"
                   @click="navGet(7)">学习中心</div>
              <div :class="path == '/wzgn' ? 'tab active' : 'tab'"
                   @click="navGet(4)">帮助中心</div>
              <div :class="path == '/tzgg' ? 'tab active' : 'tab'"
                   @click="navGet(5)">新闻资讯</div>
              <div :class="path == '/zscy' ? 'tab active' : 'tab'"
                   @click="navGet(6)">证书查验</div>
              <div :class="path == '/studyMain1'  ? 'tab active' : 'tab'"
                   @click="navGet(8)">申请发票</div>

              <div style="
                position: absolute;
                top: 0px;
                right: -200px;
                font-size: 16px;
                font-weight: bold;
                color: #ed0f08;
                width: 220px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                background: rgba(237, 15, 8, 0.1);
                border-radius: 6px;
              ">
                客服联系: 400-8816-822
              </div>
            </div>
          </div>
        </div>
        <div class="navBorder"></div>
      </div>
    </div>

    <div class="banss">
      <div class="w">
        <div class="flex resources">
          <div class="leftTop">课程资源库会员权益</div>
          <img style="width: 1200px; height: 120px; position: absolute; top: 0; left: 0; z-index: -1"
               src="../../assets/images/resources.png"
               alt="" />

          <div class="flex"
               style="justify-content: space-between; width: 100%"
               v-if="isMember !== '02'">
            <div style="color: #fff">
              <div style="font-size: 22px; font-weight: bold">开通个人会员，享受课程资源库免费观看特权</div>
              <div style="margin-top: 8px; font-size: 14px; opacity: 0.7">
                ·开通会员365天内享受特权&emsp;·课程资源可不限量免费观看&emsp;·如有其他疑问请联系客服400-8816-822
              </div>
            </div>
            <div class="hyBtn"
                 @click="ktVip">立即开通会员</div>
          </div>

          <div class="flex"
               style="justify-content: space-between; width: 100%"
               v-else>
            <div style="color: #fff">
              <div style="font-size: 22px; font-weight: bold">恭喜你！！！已开通课程资源库会员特权</div>
              <div style="margin-top: 8px; font-size: 14px; opacity: 0.7">
                ·开通会员365天内享受特权&emsp;·课程资源可不限量免费观看&emsp;·如有其他疑问请联系客服400-8816-822
              </div>
            </div>
            <div style="color: #fff;text-align: center;margin-top: 12px;">
              <div>会员特权截止日期</div>
              <div>{{ expTime }}</div>
            </div>
          </div>

        </div>
        <div class="boxList">
          <div class="flex">
            <div style="
                color: #333;
                font-size: 14px;
                font-weight: bold;
                width: 76px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                margin: 2px 16px 0 0;
              ">
              行业：
            </div>
            <div class="flex"
                 style="flex-wrap: wrap; width: 100%">
              <div :class="tabIndex == index ? 'hand active' : 'hand'"
                   @click="navCode(item, index)"
                   v-for="(item, index) in childCodes"
                   :key="item.CODE_NO">
                {{ item.CODE_NAME }}
              </div>
            </div>
          </div>

          <div class="flex"
               style="margin-top: 20px">
            <div style="
                color: #333;
                font-size: 14px;
                font-weight: bold;
                width: 76px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                margin: 2px 16px 0 0;
              ">
              专业：
            </div>
            <div class="flex"
                 style="flex-wrap: wrap; width: 100%">
              <div :class="tabIndex2 == idx ? 'hand active' : 'hand'"
                   @click="navCode2(item, idx)"
                   v-for="(item, idx) in childCodes2"
                   :key="item.CODE_NO">
                {{ item.CODE_NAME }}
              </div>
            </div>
          </div>
          <div class="sx">已为你筛选，共有{{ total }}条结果</div>

          <div>
            <div class="faList"
                 v-if="rowList.length !== 0">
              <div class="sonBox"
                   v-for="item in rowList"
                   :key="item.ID">
                <div @click="goDetils(item)">
                  <img v-if="item.C_IMAGE && item.C_IMAGE !== null && item.C_IMAGE !== ''"
                       style="width: 198px; height: 160px; border-radius: 10px 10px 0px 0px"
                       :src="item.C_IMAGE"
                       @error="handleError"
                       alt="" />
                  <div class="boxImg"
                       v-else
                       style="
                      position: relative;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 198px;
                      height: 160px;
                      border-radius: 10px 10px 0px 0px;
                    ">
                    <img style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 1; border-radius: 10px 10px 0px 0px"
                         src="../../assets/indexImg/noimg.png"
                         alt="" />
                    <div style="
                        position: relative;
                        z-index: 2;
                        font-size: 15px;
                        font-family: kaiti;
                        text-align: center;
                        padding: 0 10px;
                        font-weight: bold;
                        color: #355fd9;
                      ">
                      {{ item.C_NAME }}
                    </div>
                  </div>

                  <div style="padding: 8px 10px">
                    <div class="product-name"
                         :title="item.C_NAME"
                         style="font-size: 16px; font-weight: bold; color: #333">
                      {{ item.C_NAME }}
                    </div>

                    <div class="flex"
                         style="margin-top: 11px;justify-content: space-around;">
                      <div class="bcsBox" >{{ item.CODE_NAME }}</div>
                      <div class="bcsBox"
                            
                           style="background: rgba(255, 128, 0, 0.1); color: #ff8000">{{ item.C_CREDITHOUR }}学时</div>
                      <div>在线</div>
                    </div>
                    <!-- 不是会员的情况下，并且未购买 -->
                    <div class="flex"
                         style="justify-content: space-between"
                         v-if="isMember !== '02'">
                      <div style="margin-top: 16px; font-size: 12px; color: #999999"></div>
                      <div style="display: flex"
                           v-if="item.isBuy == '01'">
                        <div class="playBtn"
                             @click="goPlay(item)">试看</div>
                        <div class="playBtn"
                             style="margin-left: 10px; background: rgb(67, 108, 243)"
                             @click="pay(item)">购买</div>
                      </div>

                      <div style="display: flex"
                           v-else>
                        <div class="playBtn"
                             @click="goVideo(item)">播放</div>
                      </div>
                    </div>
                    <div class="flex"
                         style="justify-content: space-between"
                         v-else>
                      <div style="margin-top: 16px; font-size: 12px; color: #999999">教师：{{ item.TEACHER_REALNAME }}</div>
                      <div style="display: flex">
                        <div class="playBtn"
                             @click="goPlay(item)">播放</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else
                 style="text-align: center; margin-top: 50px; font-size: 18px">暂无数据~</div>
            <pagination v-show="total > 0"
                        :total="total"
                        :page.sync="queryParams.pageNum"
                        :limit.sync="queryParams.pageSize"
                        @pagination="toCourseList" />
          </div>
        </div>
        <!-- <div style="margin-top: 25px">
          <div class="flex">
            <div>
              <img style="width: 18px; margin-right: 8px"
                   src="../../assets/images/time.png"
                   alt="" />
            </div>

            <div style="font-size: 18px; line-height: 18px; color: #2878ff">最新发布</div>
          </div>

          <div class="flex"
               style="flex-wrap: wrap">
            <div class="listBox2"
                 v-for="item in latestReleaseCourseList"
                 :key="item.ID"
                 @click="goPlay(item)">
              <div>
                <img v-if="item.C_IMAGE && item.C_IMAGE !== null && item.C_IMAGE !== ''"
                     style="width: 180px; height: 149px"
                     :src="item.C_IMAGE"
                     alt=""
                     @error="handleError" />

                <div class="boxImg"
                     v-else
                     style="position: relative; display: flex; justify-content: center; align-items: center; width: 180px; height: 149px">
                  <img style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 1"
                       src="../../assets/indexImg/noimg.png"
                       alt="" />
                  <div style="
                      position: relative;
                      z-index: 2;
                      font-size: 15px;
                      font-family: kaiti;
                      text-align: center;
                      padding: 0 10px;
                      font-weight: bold;
                      color: #355fd9;
                    ">
                    {{ item.C_NAME }}
                  </div>
                </div>
              </div>
              <div class="textList">{{ item.C_NAME }}</div>
            </div>
          </div>
        </div> -->

        <div style="margin-top: 40px">
          <div class="flex">
            <div>
              <img style="width: 18px; margin-right: 8px"
                   src="../../assets/images/time.png"
                   alt="" />
            </div>

            <div style="font-size: 18px; line-height: 18px; color: #2878ff">推荐课程</div>
          </div>

          <div class="flex"
               style="flex-wrap: wrap">
            <div class="listBox2"
                 v-for="item in recommendCourseList"
                 :key="item.ID"
                 @click="goPlay(item)">
              <div>
                <img v-if="item.C_IMAGE && item.C_IMAGE !== null && item.C_IMAGE !== ''"
                     style="width: 180px; height: 149px"
                     :src="item.C_IMAGE"
                     alt=""
                     @error="handleError" />

                <div class="boxImg"
                     v-else
                     style="position: relative; display: flex; justify-content: center; align-items: center; width: 180px; height: 149px">
                  <img style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 1"
                       src="../../assets/indexImg/noimg.png"
                       alt="" />
                  <div style="
                      position: relative;
                      z-index: 2;
                      font-size: 15px;
                      font-family: kaiti;
                      text-align: center;
                      padding: 0 10px;
                      font-weight: bold;
                      color: #355fd9;
                    ">
                    {{ item.C_NAME }}
                  </div>
                </div>
              </div>
              <div class="textList">{{ item.C_NAME }}</div>
            </div>
          </div>
        </div>

        <!-- <div style="margin-top: 40px">
          <div class="flex">
            <div>
              <img style="width: 18px; margin-right: 8px"
                   src="../../assets/images/time.png"
                   alt="" />
            </div>

            <div style="font-size: 18px; line-height: 18px; color: #2878ff">猜你喜欢</div>
          </div>

          <div class="flex"
               style="flex-wrap: wrap">
            <div class="listBox2"
                 v-for="item in recommendSizeList"
                 :key="item.ID"
                 @click="goPlay(item)">
              <div>
                <img v-if="item.C_IMAGE && item.C_IMAGE !== null && item.C_IMAGE !== ''"
                     style="width: 180px; height: 149px"
                     :src="item.C_IMAGE"
                     alt=""
                     @error="handleError" />

                <div class="boxImg"
                     v-else
                     style="position: relative; display: flex; justify-content: center; align-items: center; width: 180px; height: 149px">
                  <img style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 1"
                       src="../../assets/indexImg/noimg.png"
                       alt="" />
                  <div style="
                      position: relative;
                      z-index: 2;
                      font-size: 15px;
                      font-family: kaiti;
                      text-align: center;
                      padding: 0 10px;
                      font-weight: bold;
                      color: #355fd9;
                    ">
                    {{ item.C_NAME }}
                  </div>
                </div>
              </div>
              <div class="textList">{{ item.C_NAME }}</div>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div>
      <div class="bottom">
        <div class="bottomContent">
          <div>
            <div class="neir">
              <div @click="goDetil(item)"
                   class="fo"
                   style="display: flex;"
                   v-for="item in this.$store.state.m_user.aboutList"
                   :key="item.ID">
                <div>
                  {{ item.TITLE }}
                </div>
                <span style="margin: 0 8px;">|</span>
              </div>

            </div>

            <!-- <div class="nd">
            联系方式:客服电话400-8816-822（ 周一至周五8:30-12:00/14:00-17:30）
          </div>
          <div class="nd">
            客服QQ: 3550339480
          </div>
          <div class="nd">
            客服QQ群: 458367595
          </div>
          <div class="nd">
            客服邮箱: zhangjuan@gc80.cn
          </div> -->
            <div v-if="this.$store.state.m_user.bottomValue">
              <div v-html="this.$store.state.m_user.bottomValue.PIC_DESC">

              </div>
            </div>
          </div>

          <div>
            <div class="tu"
                 v-if="this.$store.state.m_user.gzhEwm">
              <img :src="this.$store.state.m_user.gzhEwm.PIC_URL"
                   :alt="this.$store.state.m_user.gzhEwm.PIC_TITLE">
              <div style="text-align: center;">
                {{ this.$store.state.m_user.gzhEwm.PIC_TITLE }}
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="tio"
           v-if="this.$store.state.m_user.beiAn">
        <a href="https://beian.miit.gov.cn/"
           target="_blank"
           v-html="this.$store.state.m_user.beiAn.PIC_DESC">

        </a>
      </div>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import { toCourseListInfo, toCourseList, getResourceList } from '@/api/lsitFs.js';
import { toGenerateOrder } from '@/api/pay.js';
import pcHeaders from '../component/head.vue';
import pcBottom from '../component/bottom.vue';
import { Loading } from 'element-ui';
import { logout } from '@/api/login.js';
import { mapMutations } from 'vuex';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    pcHeaders,
    pcBottom
  },
  data() {
    // 这里存放数据
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      childCodes: [], //分类list
      latestReleaseCourseList: [], //最新发布列表
      recommendCourseList: [], //猜你喜欢list
      recommendSizeList: [], //推荐课程
      rowList: [],
      defaultImageUrl: require('../../assets/images/noimg.png'),
      tabIndex: 0,
      tabIndex2: 0,
      isMember: '',
      expTime: '',
      childCodes2: [], //2级分类
      iconSHow: false,
      indexShow: 1,
      logo: '',
      url: '',
      list: [],
      path: '',
      className: '',
      userName: '', //用户名称
      localValue: '0702',
      fp:'',
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    ...mapMutations('m_user', ['REAL_NAME']),
    //封面点击
    goDetils(item) {
      if (this.isMember !== '02') {
        if (item.isBuy == '01') {
          this.goPlay(item)
        }else {
          this.goVideo(item)
        }
      }else {
        this.goPlay(item)
      }
    },
    //点击姓名跳到学习中心
    goStudy() {
      this.$router.push({
        path: '/studyMain'
      });
    },
    //更多
    moke() {
      this.$router.push({
        path: '/lsitFs'
      });
    },
    //目录点击
    goList(msg, item) {
      console.log(msg, item);
      this.$router.push({
        path: '/lsitFs',
        query: {
          codeNo: msg.codeNo, //第二级
          fCodeNo: item.codeNo //第一级
        }
      });
    },
    //退出登录
    loginOut() {
      this.REAL_NAME('');
      localStorage.removeItem('token');
      this.userName = '';
      logout({}).then(res => {
        this.$message({
          message: '退出登录成功！',
          type: 'success'
        });
        this.$router.push('/');
      });
    },
    searClass() {
      this.$router.replace({ path: '/resourcesList', query: {} }); //清空query
      this.queryParams.courseName = this.className
      this.queryParams.codeNo = "09"
      this.queryParams.pageNum = 1
      this.queryParams.pageSize = 10
      this.tabIndex = 0
      this.tabIndex2 = null
      this.toCourseList()
    },
    changeKe() {
      this.iconSHow = !this.iconSHow;
      console.log(this.iconSHow);
    },
    login() {
      this.$router.push('/login');
    },
    regist() {
      this.$router.push('/regist');
    },
    navGet(id) {
      this.indexShow = id;
      console.log(this.indexShow);

      if (id == 1) {
        this.$router.push('/').catch(err => {
          console.log('重复路由');
        });
      } else if (id == 2) {
        this.$router.push('/lsitFs').catch(err => {
          console.log('重复路由');
        });
      } else if (id == 3) {
        this.$router.push('/car').catch(err => {
          console.log('重复路由');
        });
      } else if (id == 4) {
        this.$router.push('/wzgn').catch(err => {
          console.log('重复路由');
        });
      } else if (id == 5) {
        this.$router.push('/tzgg').catch(err => {
          console.log('重复路由');
        });
      } else if (id == 6) {
        this.$router.push('/zscy').catch(err => {
          console.log('重复路由');
        });
      } else if (id == 7) {
        this.$router.push('/studyMain').catch(err => {
          console.log('重复路由');
        });
      } else if (id == 8) {
        this.$router
          .push({
            path: '/studyMain1',
            query: {
              id: '8'
            }
          })
          .catch(err => {
            console.log('重复路由');
          });
      }
    },
    destroyed() {
      localStorage.removeItem('userName1');
    },
    goDetil(item) {
      console.log(item);
      this.$router.push({
        path: '/bottomDetil',
        query: {
          name: item.NAME,
        }
      });
    },
    goVideo(item) {
      this.$router.push({
        path: '/fraction',
        query: {
          courseId: item.ID
        }
      });
    },
    getResourceList() {
      getResourceList({}).then(res => {
        console.log(res.data.data);
        this.className = this.$route.query.name
        this.childCodes = res.data.data;
        this.childCodes2 = res.data.data[0].children;
        this.tabIndex = 0;
        this.tabIndex2 = null;
        if (this.$route.query.name) {
          this.queryParams.codeNo = '09';
        }else {
          console.log(res.data.data[0]);
          this.queryParams.codeNo =  res.data.data[0].CODE_NO;
        }
        this.queryParams.courseName = this.$route.query.name
        this.queryParams.pageNum = 1;
        this.toCourseList();
      });
    },
    ktVip() {
      // 对响应数据做点什么
      let loadingInstance = Loading.service({
        lock: true,
        text: '创建订单中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, .2)'
      });
      let data = {
        isMember: '02'
      };
      toGenerateOrder(data).then(res => {
        console.log(res);
        loadingInstance.close();
        if (res.data.data.code == '1') {
          this.$router.push({
            path: '/toPayOrder',
            query: {
              orderId: res.data.data.orderId
            }
          });
        } else if (res.data.data.code == '4') {
          this.$router.push({
            path: '/paySuccess',
            query: {
              orderId: res.data.data.orderId
            }
          });
        } else if (res.data.data.code == '3') {
          this.$message(res.data.data.msg);
          return;
        }
      });
    },

    pay(item) {
      console.log(item.ID);
      let data = {
        courseId: item.ID
      };
      toGenerateOrder(data).then(res => {
        console.log(res);
        if (res.data.data.code == '1') {
          this.$router.push({
            path: '/toPayOrder',
            query: {
              orderId: res.data.data.orderId
            }
          });
        } else if (res.data.data.code == '4') {
          this.$router.push({
            path: '/paySuccess',
            query: {
              orderId: res.data.data.orderId
            }
          });
        } else if (res.data.data.code == '3') {
          this.$message(res.data.data.msg);
          return;
        }
      });
    },
    handleError(event) {
      event.target.src = this.defaultImageUrl;
    },
    //跳转
    goPlay(item) {
      console.log(item);
      this.$router.push({
        path: '/resources',
        query: {
          courseId: item.ID
        }
      });
    },
    //分类点击
    navCode(item, index, id) {
      if (id == 0) {
        this.tabIndex2 = null;
      }
      this.tabIndex = index;
      this.tabIndex2 = null;
      this.childCodes2 = item.children;
      this.queryParams.codeNo = item.CODE_NO;
      this.className = ''
      this.queryParams.courseName = ''
      this.queryParams.pageNum = 1;
      this.toCourseList();
    },
    toCourseListInfo() {
      console.log(this.$store.state.m_user.isLogin);
      toCourseListInfo().then(res => {
        this.latestReleaseCourseList = res.data.data.latestReleaseCourseList;
        this.recommendCourseList = res.data.data.recommendCourseList;
        this.recommendSizeList = res.data.data.recommendSizeList;
      });
    },
    //二级点击
    navCode2(item, index) {
      this.queryParams.pageNum = 1;
      this.tabIndex2 = index;
      this.queryParams.codeNo = item.CODE_NO;
      this.className = ''
      this.queryParams.courseName = ''
      this.toCourseList();
    },
    toCourseList() {
      this.queryParams.token = this.$store.state.m_user.token;
      toCourseList(this.queryParams).then(res => {
        this.total = res.data.data.ph.total;
        this.rowList = res.data.data.ph.rows;
        this.isMember = res.data.data.isMember;
        if (res.data.data.expTime) {
          this.expTime = res.data.data.expTime.substring(0, 10);
        }
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.toCourseListInfo();
    this.getResourceList();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeDestroy() {}
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.head {
  font-family: Regular;
  width: 100%;
  background: #fff;
  padding-top: 20px;

  .headBanner {
    width: 1200px;
    display: flex;
    height: 80px;
    justify-content: space-between;

    .logoImg {
      width: 220px;
      height: 60px;
    }
    .searChBox {
      width: 350px;
      height: 40px;
      margin-top: 12px;
      position: relative;
      .el-input__inner {
        width: 350px !important;
        height: 40px !important;
        background: #ffffff !important;
        border: 1px solid #dddddd;
        border-radius: 20px !important;
        padding-left: 46px !important;
        padding-right: 80px !important;
        position: relative !important;
        color: #dddddd !important;
      }
      .searCh {
      }
      .searchIcon {
        position: absolute;
        top: 10px;
        left: 20px;
        width: 16px;
        height: 16px;
      }
      .searBtn {
        position: absolute;
        right: 0;
        top: 0;
        width: 80px;
        height: 40px;
        background: #2878ff;
        border: 1px solid #2878ff;
        opacity: 1;
        font-size: 16px;
        color: #fff;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
        border-radius: 0px 20px 20px 0px;
      }
    }
    .userxi {
      margin-top: 25px;
      font-size: 14px;
      color: #333;
      display: flex;
      height: 20px;
      .mlet {
        margin-left: 5px;
      }
      .hov:hover {
        opacity: 0.7;
      }
    }
  }
  .navBox {
    width: 1200px;
    height: 48px;
    display: flex;
    .tab1 {
      width: 220px;
      height: 48px;
      background: #2878ff;
      opacity: 1;
      border-radius: 0px;
      padding-left: 10px;
      cursor: pointer;
      display: flex;
      font-size: 18px;
      color: #fff;
      display: flex;
      align-items: center;
      position: relative;
      .tab1List {
        position: absolute;
        bottom: -403px;
        width: 220px;
        height: 403px;
        background: #fff;
        left: 0;
        z-index: 9999;
        box-shadow: 0px 0px 4px #333333;
        font-size: 16px;
        color: #000;
        padding: 12px 10px;
        .list1 {
          margin-bottom: 30px;
          cursor: pointer;
          .slist {
            display: flex;
            margin-top: 10px;
            flex-wrap: wrap;
            .slistbox {
              cursor: pointer;
              font-size: 14px;
              color: #999;
              opacity: 0.7;
              margin-right: 10px;
              margin-bottom: 10px;
            }
            .slistbox:hover {
              opacity: 1;
            }
          }
        }
      }
      .lu {
        width: 20px;
        height: 16px;
        margin-top: 5px;
      }
      .textLus {
        margin-left: 10px;
      }
      .topIcon {
        width: 15px;
        height: 8px;
        margin-top: 9px;
        margin-left: 54px;
      }
    }

    .tab {
      width: 120px;
      text-align: center;
      line-height: 48px;
      font-size: 16px;
      cursor: pointer;
    }
    .active {
      color: #2878ff;
      font-weight: bold;
    }
  }
  .navBorder {
    width: 100%;
    border-bottom: 2px solid #2878ff;
  }
}
a {
  color: #fff;
  text-decoration: none;
}
.bottom {
  font-family: Regular;
  width: 100%;
  height: 249px;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  background: #474747;
  .bottomContent {
    width: 1200px;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    .neir {
      color: #fff;
      display: flex;
      cursor: pointer;
      .fo:last-child {
        span {
          display: none;
        }
      }
    }
    .nd {
      margin: 15px 0;
    }
    .tu {
      img {
        width: 150px;
        height: 150px;
      }
    }
  }
}
.tio {
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 100%;
  background: #333;
  color: #fff;
  font-size: 14px;
  .footer-section2 {
    margin: 0;
  }
}
-webkit-scrollbar {
  display: none;
}
.flex {
  display: flex;
}

.banss {
  display: flex;
  justify-content: center;
  position: relative;
  background: #f4f5f7;
  .bac {
    position: absolute;
    top: 0;
    width: 100%;
    background: #f7f7f7;
    height: 265px;
  }
  .w {
    width: 1200px;
    position: relative;
    z-index: 9;
    padding: 16px 0;
    .resources {
      position: relative;
      width: 1200px;
      height: 120px;
      padding: 30px 40px 30px 134px;
      cursor: pointer;
      .leftTop {
        position: absolute;
        top: 0;
        left: 0;
        width: 134px;
        padding-left: 9px;
        height: 22px;
        line-height: 22px;
        color: #fff;
        z-index: 2;
        background: url('../../assets/images/topleft.png');
        font-size: 11px;
      }
      .hyBtn {
        width: 110px;
        height: 36px;
        background: linear-gradient(90deg, #fdf8ef 0%, #f9dea2 100%);
        cursor: pointer;
        text-align: center;
        line-height: 36px;
        font-size: 14px;
        color: #957b5f;
        margin-top: 12px;
      }
    }
    .fs {
      margin-left: 5px;
      font-size: 30px;
      font-weight: bold;
      line-height: 28px;
      color: #333333;
      line-height: 35px;
    }
    .listBox2 {
      width: 180px;
      height: 190px;
      margin-top: 18px;
      margin-right: 20px;
      background: #ffffff;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.09);
      opacity: 1;
      cursor: pointer;
      .textList {
        width: 180px;
        height: 40px;
        padding: 0 5px;
        line-height: 40px;
        font-size: 14px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .sx {
      text-align: center;
      width: 100%;
      margin: 21px 0 26px 0;
      color: #999999;
      font-size: 12px;
    }
    .boxList {
      margin-top: 20px;
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 4px 10px rgba(223, 223, 223, 0.4);
      padding: 8px 20px;
      .hand {
        margin: 2px 5px;
        box-sizing: border-box;
        height: 30px;
        padding: 0 14px;
        text-align: center;
        line-height: 30px;
        color: #333333;
        cursor: pointer;
      }
      .hand:hover {
        border-radius: 15px 15px 15px 15px;
        color: #fff !important;
        background: #2878ff !important;
      }
      .active {
        color: #fff !important;
        background: #2878ff !important;
        border-radius: 15px 15px 15px 15px;
      }
    }

    .faList {
      display: flex;
      margin-top: 5px;
      margin-left: 10px;
      flex-wrap: wrap;
      .sonBox {
        width: 200px;
        height: 300px;
        background: #ffffff;
        border-radius: 10px 10px 10px 10px;
        border: 1px solid #eaeaea;
        opacity: 1;
        box-sizing: border-box;
        display: flex;
        margin-right: 35px;
        margin-bottom: 10px;
        cursor: pointer;
        .bcsBox {
          font-size: 12px;
          font-family: siyuan;
          font-weight: 500;
          line-height: 17px;
          color: #2878ff;
          background: rgba(40, 120, 255, 0.1);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 5px;
        }
        .product-name {
          width: 180px;
          height: 45px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .product-name::-webkit-scrollbar {
          display: none !important;
        }
        .playBtn {
          width: 64px;
          height: 30px;
          background: #ef8733;
          border-radius: 15px 15px 15px 15px;
          opacity: 1;
          font-size: 16px;
          font-family: siyuan;
          font-weight: 500;
          line-height: 30px;
          font-size: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          margin-top: 10px;
        }
      }
      .sonBox:nth-child(5n) {
        margin-right: 0 !important;
      }
    }
  }
}
</style>
