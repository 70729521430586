import request from '../utils/request';
//班级项目分类--true
export function getProjectCodeList(data) {
  return request({
    url: '/project/getProjectCodeList.html',
    method: 'post',
    data
  });
}

//根据分类获取班级列表--true
export function getClassList(data) {
  return request({
    url: '/project/getClassList.html',
    method: 'post',
    data
  });
}


//班级详情
export function getClassDetail(data) {
  return request({
    url: '/project/getClassDetail.html',
    method: 'post',
    data
  });
}

//课程列表
export function getClassCourseList(data) {
  return request({
    url: '/project/getClassCourseList.html',
    method: 'post',
    data
  });
}

//热门推荐
export function getHotClassList(data) {
  return request({
    url: '/project/getHotClassList.html',
    method: 'post',
    data
  });
}

//独立考试
export function findClassIndependentQuiz(data) {
  return request({
    url: '/project/findClassIndependentQuiz.html',
    method: 'post',
    data
  });
}


//课程详情
export function getCourseDetail(data) {
  return request({
    url: '/project/getCourseDetail.html',
    method: 'post',
    data
  });
}


//课程详情页面
export function toCourseDetail(data) {
  return request({
    url: '/web/toCourseDetail.html',
    method: 'post',
    data
  });
}


//获取课程资源库
export function toCourseListInfo() {
  return request({
    url: '/web/toCourseListInfo.html',
    method: 'get',
  });
}

//获取课程资源库
export function toCourseList(data) {
  return request({
    url: '/web/toCourseList.html',
    method: 'post',
    data
  });
}

//获取课程资源库分类
export function getResourceList(data) {
  return request({
    url: '/code/getResourceList.html',
    method: 'post',
    data
  });
}

//引导购买
export function toTrialCourceInfo(data) {
  return request({
    url: '/web/toTrialCourceInfo.html',
    method: 'post',
    data
  });
}



